import * as React from "react"
import SideBarLayout from "../../components/SideBarLayout"
import CloseIcon from "@material-ui/icons/Close"
import MailIcon from "@material-ui/icons/Mail"
import TextField from "@material-ui/core/TextField"
import { StaticImage } from "gatsby-plugin-image"
import InputAdornment from "@material-ui/core/InputAdornment"
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import SCButton from "../../components/buttons/SCButton"
import PersonIcon from "@material-ui/icons/Person"
import PhoneIcon from "@material-ui/icons/Phone"
import ContentPageLayout from "../../components/ContentPageLayout"
import CommunityContentLayout from "../../components/CommunityContentLayout"
import CreateIcon from "@material-ui/icons/Create"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import firebase from "firebase"
import { RepeatOneSharp } from "@material-ui/icons"
import { uploadMedia } from "../../api/content"
import config from "../../config"
import { navigate } from "gatsby"
import AvatarEdit from "../../components/social/AvatarEdit"
import {
  errorsForm,
  validateAge,
  validateApellidos,
  validateHeight,
  validateNames,
  validateNickname,
  validatePhone,
  validateWeight,
} from "../../components/forms/helper/validationsRegexFuncs"
import InputTexfieldRegex from "../../components/forms/InputTexfieldRegex"
import UpdateMessage from "../../components/UpdateMessage"

const profileUri = `${config.serverUri}/users`
const baseUri = config.serverUri

const ProfilePage = ({ location }) => {
  const [isPremium, setIsPremium] = React.useState(false)

  const [formValues, setFormValues] = React.useState({
    id: "",
    avatar: "",
    email: "",
    userName: "",
    firstName: "",
    lastName: "",
    dateOfBirth: undefined,
    gender: "",
    tel: "",
    weight: "",
    height: "",
    age: "",
  })

  const [error, setError] = React.useState()

  React.useEffect(() => {
    let user = firebase.auth().currentUser
    if (user) {
      fetch(profileUri + `?email=${user.email}`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              const account = data[0]

              let avatar = ""

              if (account.avatar && account.avatar.slice(0, 4) === "http")
                avatar = account.avatar
              else if (!account.avatar) avatar = account.avatar
              else avatar = `${account.avatar}`
              setIsPremium(account.suscription === "premium")
              setFormValues({
                ...formValues,
                id: account.id,
                email: account.email,
                userName:
                  account.username.length <= 50
                    ? account.username
                    : account.username.slice(0, 50),
                lastName:
                  account.apellido == "undefined" ? "" : account.apellido,
                firstName: account.nombre,
                tel: account.telefono === undefined ? "" : account.telefono,
                gender: account.sexo === null ? "" : account.sexo,
                avatar: avatar,
                height: account.estatura === undefined ? "" : account.estatura,
                weight: account.peso === undefined ? "" : account.peso,
                dateOfBirth: account.fechaNacimiento,
                age: "",
              })
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      navigate("/")
    }
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        navigate("/")
      }
    })
  }, [])

  React.useEffect(() => {
    _calculateAge(new Date(formValues.dateOfBirth))
  }, [formValues.dateOfBirth])

  const todayDate = () => {
    let year = new Date(Date.now()).getFullYear().toString(),
      month = (new Date(Date.now()).getMonth() + 1).toString(),
      day = new Date(Date.now()).getDate().toString(),
      today = `${year}-${month}-${day}`

    return today
  }

  function _calculateAge(birthday) {
    // b
    if (birthday && birthday.getTime) {
      var ageDifMs = Date.now() - birthday.getTime()
      var ageDate = new Date(ageDifMs) // miliseconds from epoch
      setFormValues({
        ...formValues,
        age: Math.abs(ageDate.getUTCFullYear() - 1970),
      })
    }
  }

  const [errorsForm] = React.useState({})
  const [showUpdateMessage, setShowUpdateMessage] = React.useState(false)
  const [typeMessage, setTypeMessage] = React.useState("")

  const handleUpdateInfo = () => {
    setTimeout(() => {
      setShowUpdateMessage(false)
    }, 5000)
  }

  const save = () => {
    setError("")
    cleanErrorMessage()
    // if(!validateForm())
    //   return;

    let formData = new FormData()
    if (validateEmpty(formValues.firstName)) {
      errorsForm.firstName = "falta el nombre"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (validateEmpty(formValues.lastName)) {
      errorsForm.lastName = "faltan los apellidos"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (validateEmpty(formValues.userName)) {
      errorsForm.userName = "falta el usuario"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (validateEmpty(formValues.tel)) {
      errorsForm.tel = "falta el telefono"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (formValues.tel.length != 10) {
      errorsForm.tel = "el telefono requiere 10 digitos"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (validateEmpty(formValues.gender)) {
      errorsForm.gender = "falta selecionar el genro"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (validateEmpty(formValues.weight)) {
      errorsForm.weight = "falta el peso"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else if (validateEmpty(formValues.height)) {
      errorsForm.height = "falta la estatura"
      setTypeMessage("incorrect")
      setShowUpdateMessage(true)
      handleUpdateInfo()
    } else {
      formData.append("nombre", formValues.firstName)
      formData.append("apellido", formValues.lastName)
      formData.append("username", formValues.userName)
      formData.append("telefono", formValues.tel)
      formData.append("sexo", formValues.gender)
      formData.append("peso", formValues.weight)
      formData.append("estatura", formValues.height)
      if (formValues.dateOfBirth) {
        formData.append("fechaNacimiento", formValues.dateOfBirth)
      }


      fetch(profileUri + `/${formValues.id}`, { method: "PUT", body: formData })
        .then(response => {
          if (response.status === 200) {
            setTypeMessage("correct")
            setShowUpdateMessage(true)
            handleUpdateInfo()
          } else {
            setTypeMessage("incorrect")
            setShowUpdateMessage(true)
            handleUpdateInfo()
          }
        })
        .catch()
    }
  }

  const calculateImc = () => {
    let peso = parseFloat(formValues.weight)
    let estatura = parseFloat(formValues.height)
    let imc = (peso / (estatura * estatura)).toFixed(2)
    setFormValues({ ...formValues, imc })
  }

  const handleInputChange = (property, value) => {
    if (property === "firstName") {
      if (value.length > 50) {
        errorsForm.firstName = "El nombre debe ser menor a 50 caracteres"
      } else if (value.length < 50) {
        errorsForm.firstName = ""
      }
    }

    if (property === "userName") {
      if (value.length > 25) {
        errorsForm.userName = "El nombre debe ser menor o igual a 25 caracteres"
      } else {
        errorsForm.userName = ""
      }
    }

    if (property === "tel") {
      if (value.length < 10) {
        errorsForm.tel = "El número telefónico no es correcto"
      } else if (value === "") {
        errorsForm.tel = ""
      } else if (!/^[0-9]/.test(value)) return false
      if (value.length > 10) {
        errorsForm.tel = "Deben ser exactamente 10 números"
      } else {
        errorsForm.tel = ""
      }
    }

    if (property === "weight") {
      if (value.length > 6) {
        errorsForm.weight = "Maximo 6 caracteres incluyendo el punto"
      } else if (parseInt(value) < 0 || parseInt(value) > 999) {
        errorsForm.weight = "Solo se aceptan valores entre 0 y 999"
      } else {
        errorsForm.weight = ""
      }
    }

    if (property === "height") {
      if (value.length > 4) {
        errorsForm.height = "Maximo 4 caracteres incluyendo el punto"
      } else if (parseInt(value) < 0 || parseInt(value) > 9) {
        errorsForm.height = "Solo se aceptan valores entre 0 y 9"
      } else {
        errorsForm.height = ""
      }
    }

    setFormValues({ ...formValues, [property]: value })
  }

  const fileChange = event => {
    let file = event.target.files[0]
    if (file) {
      uploadMedia(file)
        .then(url => {
          let formData = new FormData()
          formData.append("avatar", url)
          fetch(profileUri + `/${formValues.id}`, {
            method: "PUT",
            body: formData,
          })
            .then(() => {
              setFormValues({ ...formValues, avatar: url })
            })
            .catch()
        })
        .catch()
    } else {
      console.log("no file selected")
    }
  }

  const fileInput = React.useRef()

  const raiseInputDialog = () => {
    fileInput.current.click()
  }

  const validateEmpty = input => input === ""

  const cleanErrorMessage = () => {
    errorsForm.firstName = ""
    errorsForm.lastName = ""
    errorsForm.nickName = ""
    errorsForm.tel = ""
    errorsForm.gender = ""
    errorsForm.weight = ""
    errorsForm.height = ""
  }

  return (
    <ContentPageLayout location={location}>
      <div>
        <div
          className="flex flex-col relative"
          style={{ height: 240, width: "100%" }}
        >
          <StaticImage
            alt="banner comunidad"
            src="../../images/banner_comunidad.png"
            objectFit="cover"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          ></StaticImage>
          <div className="absolute w-full p-4 flex-col justify-center text-center">
            <div className="md:hidden mb-4">
              <AvatarEdit
                avatar={formValues.avatar}
                id={formValues.id}
                onUploadComplete={url => {
                  setFormValues({ ...formValues, avatar: url })
                }}
              ></AvatarEdit>
            </div>

            <h2 className="text-white md:hidden">Cuenta premium</h2>
          </div>
        </div>
      </div>
      <CommunityContentLayout avatar={formValues.avatar} isPremium={isPremium}>
        <h2 className="hidden md:block">Datos de usuario</h2>
        <div className="flex justify-center hidden md:flex">
          <div className="relative inline-block">
            {formValues.avatar ? (
              <img
                className="inline-block object-cover w-32 h-32 rounded-full"
                src={formValues.avatar}
                alt="Profile image"
              />
            ) : (
              <StaticImage
                alt="user picture"
                src="../../images/userprofile.png"
                className="inline-block object-cover w-32 h-32 rounded-full"
              ></StaticImage>
            )}
            <div
              className="absolute bottom-0 right-0 inline-block bg-white flex justify-center items-center w-12 h-12  rounded-full shadow border text-sc-primary cursor-pointer nm"
              onClick={raiseInputDialog}
            >
              <CreateIcon></CreateIcon>
            </div>
            <input
              type="file"
              onChange={fileChange}
              ref={fileInput}
              multiple={false}
              hidden
            ></input>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between p-2 md:p-8">
          <div className="w-full md:w-5/12">
            <p>
              <b>Información personal</b>
            </p>
            <div>
              <div className="w-full mb-8">
                <TextField
                  size="small"
                  className="w-full"
                  id="input-with-icon-textfield"
                  placeholder="Email"
                  value={formValues.email}
                  onChange={e => {
                    handleInputChange("email", e.target.value)
                  }}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CheckIcon></CheckIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.firstName}
                  property="firstName"
                  setOnChange={handleInputChange}
                  placeHolder={"Nombre(s)"}
                  validateFunc={validateNames}
                  type="string"
                >
                  <PersonIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
                {errorsForm.firstName ? (
                  <div style={{ color: "red" }}>{errorsForm.firstName}</div>
                ) : null}
              </div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.lastName}
                  property="lastName"
                  setOnChange={handleInputChange}
                  placeHolder={"Apellido(s)"}
                  validateFunc={validateApellidos}
                  type="string"
                >
                  <PersonIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
                {errorsForm.lastName ? (
                  <div style={{ color: "red" }}>{errorsForm.lastName}</div>
                ) : null}
              </div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.userName}
                  property="userName"
                  setOnChange={handleInputChange}
                  placeHolder={"Nombre de Usuario"}
                  validateFunc={validateNickname}
                  type="other"
                >
                  <PersonIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
                {errorsForm.userName ? (
                  <div style={{ color: "red" }}>{errorsForm.userName}</div>
                ) : null}
              </div>
              <div className="w-full mb-8">
                <TextField
                  className="w-full"
                  id="input-with-icon-textfield"
                  type="date"
                  label="Fecha de nacimiento"
                  value={formValues.dateOfBirth}
                  onChange={e => {
                    handleInputChange("dateOfBirth", e.target.value)
                  }}
                  InputProps={{
                    inputProps: {
                      min: "1921-01-01",
                      max: `${todayDate()}`,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon></CalendarTodayIcon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CheckIcon></CheckIcon>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorsForm.dateOfBirth ? (
                  <div style={{ color: "red" }}>{errorsForm.dateOfBirth}</div>
                ) : null}
              </div>
              <div className="w-full mb-8">
                <FormControl className="w-full" style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue=""
                    value={formValues.gender}
                    onChange={e => {
                      handleInputChange("gender", e.target.value)
                    }}
                  >
                    <MenuItem value={"masculino"}>Masculino</MenuItem>
                    <MenuItem value={"femenino"}>Femenino</MenuItem>
                    <MenuItem value={"otro"}>Otro</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.tel}
                  property="tel"
                  setOnChange={handleInputChange}
                  placeHolder={"Teléfono"}
                  validateFunc={validatePhone}
                  type="int"
                >
                  <PhoneIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
                {errorsForm.tel ? (
                  <div style={{ color: "red" }}>{errorsForm.tel}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full md:w-5/12">
            <p>
              <b>Acerca de tu salud</b>
            </p>
            <div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.weight}
                  property="weight"
                  setOnChange={handleInputChange}
                  placeHolder={"Peso (kg)"}
                  validateFunc={validateWeight}
                  type="float"
                >
                  <PersonIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
                {errorsForm.weight ? (
                  <div style={{ color: "red" }}>{errorsForm.weight}</div>
                ) : null}
              </div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.height}
                  property="height"
                  setOnChange={handleInputChange}
                  placeHolder={"Estatura (metros)"}
                  validateFunc={validateHeight}
                  type="float"
                >
                  <PersonIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
                {errorsForm.height ? (
                  <div style={{ color: "red" }}>{errorsForm.height}</div>
                ) : null}
              </div>
              <div className="w-full mb-8">
                <InputTexfieldRegex
                  onChange={formValues.age}
                  property="age"
                  setOnChange={handleInputChange}
                  placeHolder={"Edad"}
                  validateFunc={validateAge}
                  type="int"
                >
                  <PersonIcon style={{ color: "#6B6B6B" }} />
                </InputTexfieldRegex>
              </div>
              <div className="w-full mb-8">
                <TextField
                  className="w-full"
                  id="input-with-icon-textfield"
                  placeholder="IMC"
                  type="number"
                  disabled
                  value={formValues.imc}
                  onChange={e => {
                    handleInputChange("age", e.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon></PersonIcon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CheckIcon></CheckIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div>
                <SCButton onClick={calculateImc}>Calcular IMC</SCButton>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-24">
            <SCButton onClick={save}>Guardar</SCButton>
            {showUpdateMessage && <UpdateMessage typeMessage={typeMessage} />}
          </div>
        </div>
        <div className="flex  justify-center">
          {error ? <span style={{ color: "red" }}>{error}</span> : null}
        </div>
      </CommunityContentLayout>
    </ContentPageLayout>
  )
}

export default ProfilePage
