import React from "react"
import spinner from "../images/spinner.svg"

const UpdateMessage = ({ typeMessage }) => {
  return (
    <div style={{ textAlign: "center", marginTop: "5px" }}>
      {typeMessage === "correct" ? (
        <h6>Datos Actualizados</h6>
      ) : (
        <h6>No se pudieron actualizar los datos</h6>
      )}
    </div>
  )
}

export default UpdateMessage
