import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CreateIcon from '@material-ui/icons/Create';
import { uploadMedia } from "../../api/content";
import config from "../../config";

const AvatarEdit  = ({avatar , id , onUploadComplete}) => {


    const fileChange = ( event) => { 
        let file = event.target.files[0]
        if(file)
        {
            uploadMedia(file).then((url)=>{
                
                        let formData = new FormData();
                        formData.append("avatar", url);                        
                        fetch(config.serverUri + `/users/${id}` , {method: "PUT", body: formData}).then(()=>{
                            if(onUploadComplete)
                            {
                                onUploadComplete(url)
                            }
                            
                        }).catch()
                    }).catch()
        }
        else{
            console.log("no file selected")
        }
    
    }

    const fileInput = React.useRef();

    const raiseInputDialog = () => {
        fileInput.current.click();
    }

    return (<div className="flex justify-center">        
    <div className="relative inline-block">
        {avatar  ? <img className="inline-block object-cover w-32 h-32 rounded-full" src={avatar} alt="Profile image"/>
        : <StaticImage alt="Image profile" src="../../images/userprofile.png" className="inline-block object-cover w-32 h-32 rounded-full"></StaticImage>}
        <div className="absolute bottom-0 right-0 inline-block bg-white flex justify-center items-center w-12 h-12  rounded-full shadow border text-sc-primary cursor-pointer nm"
        onClick={raiseInputDialog}
        >
            <CreateIcon></CreateIcon>
        </div>
        <input type="file" onChange={fileChange} ref={fileInput} multiple={false} hidden></input>                           
    </div>
</div>)

}

export default AvatarEdit 