import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { HiMenu } from "@react-icons/all-files/hi/HiMenu"
import { MdClose } from "@react-icons/all-files/md/MdClose"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { navigate, Link } from "gatsby"

import "../styles/site.css"

import firebase from "firebase"
import { firebaseInit } from "../config"



const SideBarLayout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true)

  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(true)
      } else setUser(false)
    })
  }, [])

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        goHome()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const navBar = () => isOpen ? setIsOpen(false) : setIsOpen(true)

  const goHome = () => {
    navigate("/")
  }

  return (
    <>
      <div
        className={`md:hidden fixed w-full h-screen bg-black z-30 right-0 ${isOpen ? 'hidden' : 'block'}`}>

        <div className="w-full h-full flex flex-col justify-start mt-36 items-center text-white">
          <ul>
            {
              user ? <li><Link to="/settings">Configuración</Link></li> : null
            }
            <li><Link to="/settings/legal">Legales</Link></li>
          </ul>
        </div>
        
        <div className="absolute bottom-6 right-6 text-white cursor-pointer flex justify-end items-end">
          <div className="flex flex-col">
            {user ? (
              <div className="flex items-center" onClick={signOut}>
                <ExitToAppIcon />
                <div style={{ marginLeft: "1rem" }}>Cerrar sesión</div>
            </div>
            ) : null}
            <div className="text-white mt-6">
              By Ironbit {new Date().getFullYear()} v0.16.9
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row relative md:h-screen overflow-y-hidden">
        <aside className="w-full h-auto fixed z-50 md:w-64 md:h-screen lg:w-1/5 bg-black p-8 m-0 md:relative">
          <div className="w-4/6 flex justify-between items-center mb-0">
            <ArrowBackIcon
              style={{ color: "#ffffff", cursor: "pointer" }}
              onClick={goHome}
            />
            <StaticImage
              width={96}
              className={`mx-0 px-0 z-0 `}
              src="../images/logo_white.png"
              alt="Shift Logo"
            />
            {
              isOpen ? (<HiMenu
                className="md:hidden fixed right-6"
                style={{
                  color: "#ffffff",
                  cursor: "pointer",
                  fontSize: "2rem",
                  cursor: "pointer",
                }}
                onClick={navBar}
              />
              ) : (
                <MdClose
                  className="md:hidden fixed right-6"
                  style={{
                    color: "#ffffff",
                    cursor: "pointer",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={navBar}
                />
              )
            }
          </div>
          <div
            className="hidden w-full md:block md:h-full">

            <div className="w-full h-full flex flex-col justify-start md:pt-14 items-center text-white md:items-start">
              <ul>
                {user ? <li><Link to="/settings">Configuración</Link></li> : null}
                <li><Link to="/settings/legal">Legales</Link></li>
              </ul>
            </div>

            <div className="absolute bottom-6 left-4 text-white flex justify-end items-end">
              <div className="flex flex-col">
                {
                  user ? (
                    <div className="flex items-center cursor-pointer" onClick={signOut}>
                      <ExitToAppIcon />
                      <div style={{ marginLeft: "1rem" }}>Cerrar sesión</div>
                    </div>
                  ) : null
                }
                <div className="text-white mt-6 cursor-default">
                  By Ironbit {new Date().getFullYear()} v0.16.9
                </div>
              </div>
            </div>

          </div>
        </aside>
        <main className="mt-10 h-auto w-full overflow-y-auto p-8 md:mt-0 ">{children}</main>
      </div>
    </>
  )
}



export default SideBarLayout
