import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout" // Importa el componente Layout de Gatsby

const CommunityContentLayout = ({ children, avatar, isPremium }) => {
  return (
    <div className="flex">
      <aside className="w-1/4 bg-black h-auto p-8 hidden md:block">
        <div className="text-white">
          <ul>
            <li className="px-8 py-2">
              <Link
                to="/profile"
                activeClassName="border-l-4 border-sc-primary" // Usa activeClassName para aplicar la clase en la opción seleccionada
                className="flex items-center mb-4"
              >
                {avatar ? (
                  <img
                    className="inline-block object-cover w-8 my-auto h-8 mx-4 rounded-full"
                    src={avatar}
                    alt="Profile image"
                  />
                ) : (
                  <StaticImage
                    alt="Profile image"
                    src="../images/userprofile.png"
                    className="inline-block object-cover w-8 h-8 mx-4 mt-0 rounded-full"
                  />
                )}

                <span>Mi perfil</span>
              </Link>
              {isPremium ? (
                <Link
                to="/preferences"
                activeClassName="border-l-4 border-sc-primary" // Usa activeClassName para aplicar la clase en la opción seleccionada
                className="flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="mx-4"
                >
                  <path d="M5 19h-4v-4h4v4" fill="#ffffff" />
                  <path d="M11 19h-4v-8h4v8" fill="#ffffff" />
                  <path d="M17 19h-4v-13h4v13" fill="#ffffff" />
                  <path d="M23 19h-4v-19h4v19" fill="#ffffff" />
                  <rect width="24" height="2" rx="1" ry="1" fill="#ffffff" />
                </svg>

                <span>Informe de gustos</span>
              </Link>
              ) : null}
            </li>
          </ul>
        </div>
      </aside>
      <main className="flex-1 p-8">{children}</main>
    </div>
  )
}

export default CommunityContentLayout
